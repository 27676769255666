













































import { AccountsClient, IResetForgotPasswordModel, ResetForgotPasswordModel } from "@/api/OtiumAppApi";
import { ref, defineComponent } from "@vue/composition-api";

import validatePassword from "@/utility/password_validator";

export default defineComponent({
  name: "ResetForgotPassword",
  setup(props, { root }) {
    const password = ref("");
    const isFormValid = ref(false);
    const errorMessage = ref("");
    const showPassword = ref(false);
    const resettingPassword = ref(false);
    const passwordRules = ref([validatePassword]);

    async function resetForgotPasswordSubmitted() {
      resettingPassword.value = true;

      const accountsClient = new AccountsClient();

      let model: IResetForgotPasswordModel = {
        email: root.$route.query["e"] as string,
        token: root.$route.query["t"] as string,
        password: password.value
      };

      let result = await accountsClient.resetForgotPassword(new ResetForgotPasswordModel(model));

      resettingPassword.value = false;

      if (result.succeeded) {
        root.$router.push({ name: "Home" });
      } else {
        errorMessage.value = result.errors?.map(e => e.description).join(" ") ?? "An error occurred."
      }
    }

    return {
      password,
      isFormValid,
      errorMessage,
      showPassword,
      passwordRules,
      resettingPassword,
      resetForgotPasswordSubmitted
    };
  }
});
