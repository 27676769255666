export class PasswordValidationResult {
  noPassword = false;
  tooShort = false;
  missingLowercase = false;
  missingCapital = false;
  missingDigit = false;
  missingNonAlphanumeric = false;
  missingUnique = false;

  isValid() {
    return !(
      this.noPassword ||
      this.tooShort ||
      this.missingLowercase ||
      this.missingCapital ||
      this.missingDigit ||
      this.missingNonAlphanumeric ||
      this.missingUnique
    );
  }
}

// password validation based on what is used by the API
export function checkPassword(password: string): PasswordValidationResult {
  const result = new PasswordValidationResult();
  result.noPassword = password.trim() == "";
  result.tooShort = password.length < 6;
  result.missingLowercase = !/[a-z]/.test(password);
  result.missingCapital = !/[A-Z]/.test(password);
  result.missingDigit = !/[0-9]/.test(password);
  result.missingNonAlphanumeric = !/[^A-Za-z0-9]/.test(password);

  // count unique characters
  const chars = new Map();
  password.split("").forEach((c) => {
    if (chars.has(c)) chars.set(c, chars.get(c) + 1);
    else chars.set(c, 1);
  });
  let uniqueCount = 0;
  chars.forEach((val) => {
    if (val == 1) uniqueCount++;
  });
  result.missingUnique = uniqueCount < 1;

  return result;
}

// used for vuetify form validation
export function validatePassword(password: string): string | boolean {
  const result = checkPassword(password);
  const errors = [];
  if (result.noPassword) errors.push("Password is required");
  if (result.tooShort) errors.push("Password must be at least 6 characters long");
  if (result.missingLowercase) errors.push("Password must contain a lowercase letter");
  if (result.missingCapital) errors.push("Password must contain a capital letter");
  if (result.missingDigit) errors.push("Password must contain a digit");
  if (result.missingNonAlphanumeric) errors.push("Password must contain a non-alphanumeric character");
  if (result.missingUnique) errors.push("Password must contain at least 1 different character");

  if (errors.length > 0) return errors.join(", ");
  else return true;
}

export default validatePassword;
